<template>
	<div class="apply">
		<gc-dialog :show.sync="visible" title="选择模版角色" ok-text="确认" :show-footer="false">
			<template>
				<gc-table :columns="columns" :table-data="tableData" style="margin-bottom: 32px"></gc-table>
			</template>
		</gc-dialog>
	</div>
</template>

<script>
import { apiGetRoleTemplateList } from '@/apis/system.api'
export default {
	name: 'Apply',
	components: {},
	props: {},
	data() {
		return {
			checked: [],
			visible: false,
			columns: [
				{
					key: 'name',
					name: '角色名称',
				},
				{
					key: 'operate',
					name: '操作',
					render: (h, row) => {
						return h(
							'span',
							{
								class: 'grant',
								on: {
									click: () => this.handleApply(row),
								},
							},
							'选择',
						)
					},
				},
			],
			tableData: [],
		}
	},
	methods: {
		handleSelectionChange(val) {
			this.checked = val
		},
		showDialog() {
			apiGetRoleTemplateList().then(({ total, dataList }) => {
				if (total === 0) return this.$message.info('暂无模板角色，请联系管理员')
				this.tableData = dataList
				this.tableData.push({
					id: 0,
					name: '初始模板',
				})

				this.visible = true
				//
			})
		},
		handleApply(row) {
			this.visible = false
			this.$emit('on-apply-template', row)
		},
		handleCancel() {
			this.visible = false
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.grant {
		font-size: 14px;
		color: #4d6bff;
		line-height: 21px;
		cursor: pointer;
	}
}
</style>
