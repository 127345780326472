import getReqUtil from '@/utils/reqUtils'
import { manageProxy } from '@/config'
const { reqDELETE, reqGET, reqPOST, reqPUT } = getReqUtil(manageProxy)

/*******************模板角色 start**********************/

// 获取模板角色列表
export const apiGetRoleTemplateList = data => reqGET(data, '/v1/utos/roles/templates')

// 获取初始角色树
export const apiGetRoleTree = data => reqPOST(data, 'auth/authz/permission/group/template/role/tree', true)

// 获取模板角色内容
export const apiGetRoleTemplateContent = data => reqPOST(data, '/auth/authz/permission/group/template/role/tree', true)

// 新建、更新、删除 模板角色
export const apiAddTemplateRole = data => reqPOST(data, '/v1/utos/roles/templates', false, false)
export const apiModifyTemplateRole = data => {
	return reqPUT(data, '/v1/utos/roles/templates')
}

export const apiDeleteTemplateRole = data => reqDELETE(data, `/v1/utos/roles/templates/${data}`)

// 功能权限重构
export const apiGetSysFunctionTree = data => reqGET(data, '/auth/authz/functional/tree/system')

export const apiGetTemplateRoleContent = data => reqGET(data, '/auth/authz/functional/granted/template-role')
// 功能权限重构

/*******************模板角色 end**********************/
