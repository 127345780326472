import {
	apiGetRoleTemplateList,
	apiAddTemplateRole,
	apiModifyTemplateRole,
	apiDeleteTemplateRole,
	apiGetSysFunctionTree,
	apiGetTemplateRoleContent,
} from '@/apis/system.api'
import {
	apiGetRoleList,
	apiAddRole,
	apiModifyRole,
	apiDeleteRole,
	apiGetRoleFunction,
	apiGetTenantFunctionTree,
} from '@/apis/organizeRole.api'

export default {
	role: {
		modelItemName: '角色名称',
		pageHeader: 'roleManage',
		addBtnText: '新增角色',
		subTitleText: '角色列表',
		emptyPageName: 'roleManage',
		apiGetInitialTree: apiGetTenantFunctionTree,
		apiGetList: apiGetRoleList,
		apiGetContent: apiGetRoleFunction,
		apiAdd: apiAddRole,
		apiDelete: apiDeleteRole,
		apiModify: apiModifyRole,
	},
	rolePermission: {
		add: 'utos:tosm:role:insert',
		edit: 'utos:tosm:role:update',
		delete: 'utos:tosm:role:delete',
	},
	templateRolePermission: {
		add: 'utos:template:role:maintain',
		edit: 'utos:template:role:maintain',
		delete: 'utos:template:role:maintain',
	},
	templateRole: {
		modelItemName: '模板角色名称',
		pageHeader: 'templateRoleManage',
		addBtnText: '新增模板角色',
		subTitleText: '模板角色列表',
		emptyPageName: 'templateRoleManage',
		apiGetInitialTree: apiGetSysFunctionTree,
		apiGetList: apiGetRoleTemplateList,
		apiGetContent: apiGetTemplateRoleContent,
		apiAdd: apiAddTemplateRole,
		apiDelete: apiDeleteTemplateRole,
		apiModify: apiModifyTemplateRole,
	},
}
